import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  children: any;
  bgColor?: any;
  boxShadow?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.typography.pxToRem(5),
      marginBottom: theme.typography.pxToRem(30),
    },
  }),
);

const Wrapper = (props: IProps): React.ReactElement => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: props.bgColor,
        boxShadow: props.boxShadow ? '0px 3px 6px #00002930' : '',
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
