import React, { FunctionComponent as FC, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import ContentList from '../components/content-list/';
import Wrapper from '../components/wrapper/Wrapper';
import BreadCrumb from '../components/pge-breadcrumb';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getContentItems } from '../util/contentful-render-utils';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(25),
    },
  }),
);

const Archive: FC = () => {
  const { page } = useStaticQuery(graphql`
    query archivePageContent {
      page: allContentfulPageNewsRelease(
        sort: { order: DESC, fields: releaseDate }
      ) {
        nodes {
          node_locale
          slug
          title: releaseTitle
          date: releaseDate(formatString: "MMM DD, YYYY")
          description: metaDescriptionSeo
        }
      }
    }
  `);
  const { language } = useContext(LanguageContext);
  const content = page.nodes.filter(
    (pg: any) =>
      pg.node_locale === (language || 'en') &&
      pg.slug.match('placeholder') === null,
  );

  const breadcrumb = [
    { to: '/', label: 'Home' },
    { to: 'newsroom', label: 'Newsroom' },
    { to: '', label: 'News Archive' },
  ];

  const tableHeader = ['Date', 'News Release'];
  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>PGE - News Archive</title>
      </Helmet>
      <Container className={useStyles().main}>
        <BreadCrumb path={breadcrumb} />
        <Wrapper>
          <ContentList
            rows={getContentItems(content)}
            tableHeader={tableHeader}
            introHeading="News Releases"
            introCopy={
              <span>
                <b>Recent media releases on company news and events</b>
              </span>
            }
            columnBackground="LightBlue"
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default Archive;
